<template>
  <DashboardTemplate :sections="sections" :grid-area-component="gridAreaComponentP">
    <template #navbarItems>
      <div class="dashboard-navbar__route">
        {{ $t("dashboard.panel-tool-title") }}
      </div>
      <div class="dashboard-navbar__separator">
        /
      </div>
      <div
        class="dashboard-navbar__route dashboard-navbar__clickable__underlined"
      >
        {{ getNamePartnership }}
      </div>
    </template>
  </DashboardTemplate>
</template>
<script>
import { mapGetters } from 'vuex';
import DashboardTemplate from '@/components/dashboard/Template.vue';

export default {
  name: 'PartnershipView',
  components: { DashboardTemplate },
  metaInfo() {
    return {
      title: `${this.$tc('dashboard.breadcrumbs.app_name')} | ${this.$tc('dashboard.breadcrumbs.partnerships')}`,
      meta: [{ name: 'robots', content: 'noindex, nofollow' }],
    };
  },
  data() {
    return {
      sections: [
        {
          name: 'partnership-container',
          title: 'Categorías',
          showTitle: false,
          background: 'transparent',
          permissions: [],
          paddingSection: '0px',
          mobileHeight: '100%',
          gridArea: 'partnership-container',
        },
      ],
      gridAreaComponentP: '"partnership-container partnership-container partnership-container partnership-container partnership-container partnership-container" "partnership-container partnership-container partnership-container partnership-container partnership-container partnership-container" "partnership-container partnership-container partnership-container partnership-container partnership-container partnership-container" "partnership-container partnership-container partnership-container partnership-container partnership-container partnership-container"',
    };
  },
  computed: {
    ...mapGetters({
      listPartnership: 'options/partnershipLabels',
    }),
    idPartnership() {
      return Number(this.$route.params.id);
    },
    getNamePartnership() {
      return this.listPartnership.find(({ id }) => id === this.idPartnership)?.name;
    },
  },
};
</script>
